import { UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { HTMLAttributes, useEffect, useState } from "react";
import "./dragDropZone.module.scss";
import { CuttingType } from "app/models/CuttingType";
import { useSelector } from "react-redux";
import { configSelector } from "app/redux/slides/config.slide";
import { ShopType } from "app/models/ShopType";
import { batchUploadFiles } from "app/utils";

interface DefaultProps extends UploadProps<any> {
  onSelectedFiles?: any;
}
var timeoutHandle: any;
function DragDropZone(props: DefaultProps) {
  const { onSelectedFiles, ...draggerProps } = props;
  const config = useSelector(configSelector);
  const [cuttingType, setCuttingType] = useState(
    config.shopType !== ShopType.BOTH && config.shopType
  );
  const [dragging, setdragging] = useState(false);
  const uploadProps: UploadProps = {
    beforeUpload: (file, fileList) => {
      if(config.shopType == ShopType.BOTH) return;
      const rs = batchUploadFiles(fileList);
      if (onSelectedFiles) onSelectedFiles({ files: rs.data });
      return false;
    },
    name: "file",
    multiple: true,
    fileList: [],
    accept: `.stp, .step, .jt, .pvz, .edz, .prt, .asm, .sldasm, .sldprt, .model, .catpart, .catproduct, .3ds, .prj, .par, .asm, .psm, .sldprt, .sldasm, .prt, .pdf, ${
      cuttingType === CuttingType.SHEET_METAL ? " .dxf, .dwg," : ""
    } ${cuttingType === CuttingType.MILLING_AND_TURNING ? " .ipt," : ""}`,
  };

  const stopDrag = () => {
    setdragging(false);
  };
  
  const onDragging = () => {
    if(config.shopType == ShopType.BOTH) return;
    setdragging(true);
    clearTimeout(timeoutHandle);
    timeoutHandle = window.setTimeout(stopDrag, 500);
  };
  return (
    <div
      className={`drop-drag-zone`}
      onDragOver={onDragging}
      onDrop={stopDrag}
    >
      <Dragger {...draggerProps} {...uploadProps} openFileDialogOnClick={false}>
        {props.children}
      </Dragger>
      <div className={`drag-layer ${dragging && "dragging"}`}>
        <h3>Drag & Drop files here.</h3>
      </div>
    </div>
  );
}

export default DragDropZone;
