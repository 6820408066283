import { WarningOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { Part } from "app/models/Part";
import { authSelector } from "app/redux/slides/auth.slide";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface DefaultProps {
  part: Part;
}

function AlertPartWarning(props: DefaultProps) {
  const { part } = props;
  const warnings = part?.warnings;
  const { t } = useTranslation();
  const auth = useSelector(authSelector);

  if (!part || !warnings || !warnings.length) return <></>;

  // const keyMessage = auth.user?.role == UserRole.BUYER ? "buyer" : "seller";
  return (
    <Alert
      className="mb-3"
      message={
        <>
          {warnings.map(w => <div>
            {t(`part.warning.${w}`)}
          </div>)}
        </>
      }
      type="warning"
      icon={<WarningOutlined />}
      showIcon
    />
  );
}

export default AlertPartWarning;
