import { KeyOutlined, ManOutlined, WomanOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  notification,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import FormBulkPricingSetting from "app/components/Form/FormBulkPricingSetting";
import FadeIn from "app/components/Shape/FadeIn";
import {
  useRulePassword,
  useRuleStringRequired,
} from "app/utils/FormValidation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import AddressForm from "../../components/Address/AddressForm";
import { User } from "../../models/User";
import { loadingActions } from "../../redux/slides/loading.slide";
import { userActions } from "../../redux/slides/user.slide";
import userServices from "../../services/user.service";
const { Option } = Select;

export function BuyerSettingPage() {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rulePassword = useRulePassword();
  const ruleStringRequired = useRuleStringRequired();
  const [profile, setProfile] = useState<User>();
  const [form] = Form.useForm();
  const [formPassword] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keySelected, setKeySelected] = useState("default");
  const [isSameShippingAddress, setIsSameShippingAddress] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [disabledCurrencySelect, setDisabledCurrencySelect] = useState(true);

  const menus = [
    {
      key: "default",
      label: t("settings.account"),
    },
    {
      key: "bulk-pricing",
      label: t("part.bulkPricing"),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    formPassword.resetFields();
  };

  const getMe = async () => {
    try {
      const rs = await userServices.me();
      setProfile(rs);
      form.setFieldsValue(rs);
    } catch (error) {
      // onLogout();
    }
  };

  const getCurrencyOptions = async () => {
    try {
      const rs = await userServices.getCurrencies();
      setCurrencies(rs.data);
    } catch (error) {}
  };

  useEffect(() => {
    getMe();
    getCurrencyOptions();
  }, []);

  useEffect(() => {
    if (currencies && profile) {
      setDisabledCurrencySelect(false);
      form.setFieldValue("currencyExchangeId", profile?.currencyExchange?.id);
    }
  }, [currencies, profile]);

  const onMenuSelected = (m: any) => {
    setKeySelected(m.key);
  };

  const onFinish = async (values: User) => {
    dispatch(loadingActions.show(true));
    try {
      const rs = await userServices.updateAccount({
        ...profile,
        ...values,
      });
      api.success({
        message: `${t("success")}!`,
        description: t("updateSuccess"),
        placement: "topRight",
      });
      dispatch(userActions.setProfile(rs));
    } catch (e: any) {
      api.error({
        message: e.error,
        description: e.message,
        placement: "topRight",
      });
    }
    dispatch(loadingActions.show(false));
  };

  const onChangePasswordFinish = async (values: any) => {
    dispatch(loadingActions.show(true));
    try {
      await userServices.changePassword(values);
      api.success({
        message: `${t("success")}!`,
        description: `Password is changed!`,
        placement: "topRight",
      });
      handleCancel();
    } catch (e: any) {
      api.error({
        message: "Failed!",
        description: t("error.default"),
        placement: "topRight",
      });
    }
    dispatch(loadingActions.show(false));
  };

  const onIsSameShippingAddress = (e: CheckboxChangeEvent) => {
    setIsSameShippingAddress(e.target.checked);
    if (e.target.checked) {
      const sa = form.getFieldValue("billingAddress");
      form.setFieldValue("shippingAddress", {
        ...sa,
        id: profile?.shippingAddress?.id,
      });
    }
  };

  const onFormChange = (values: any) => {
    if (isSameShippingAddress && values.billingAddress) {
      const shippingAddress = { ...form.getFieldValue(["shippingAddress"]) };
      const id = shippingAddress.id;
      form.setFieldValue(["shippingAddress"], {
        ...form.getFieldValue(["billingAddress"]),
        id,
      });
    }
  };
  return (
    <div>
      {contextHolder}
      <h4>{t("accountSettings")}</h4>
      <div className="b-settings">
        <div className="row">
          <div className="col col-12 col-lg d-lg-flex flex-lg-column gap-3 b-menus">
            {menus.map((m) => (
              <Button
                key={`m-${m.key}`}
                type={m.key === keySelected ? "primary" : "default"}
                shape="round"
                onClick={onMenuSelected.bind(null, m)}
              >
                {m.label}
              </Button>
            ))}
          </div>
          <div className="col col-12 col-lg-8 col-xl-10">
            {keySelected === "default" && (
              <Form
                className="app-form"
                form={form}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  country: "Deutschland",
                }}
                onValuesChange={onFormChange}
              >
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>
                <div className="row">
                  <div className="col col-12 col-lg-6">
                    <p className="sub-l">{t("contactPerson")}</p>
                    <div className="row">
                      <div className="col col-12 col-md-6">
                        <Form.Item
                          label={t("firstName")}
                          name="firstName"
                          rules={ruleStringRequired}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="col col-12 col-md-6">
                        <Form.Item
                          label={t("lastName")}
                          name="lastName"
                          rules={ruleStringRequired}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="mb-3" onClick={showModal}>
                        <Button icon={<KeyOutlined />}>
                          {t("changePassword")}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col col-12 col-lg-6">
                    <div className="row">
                      <p className="sub-l">{t("contactInfomation")}</p>
                      <div className="col col-12 col-md-6">
                        <Form.Item name="gender" label={t("gender")}>
                          <Radio.Group>
                            <Radio.Button value="MALE">
                              <ManOutlined /> {t("male")}
                            </Radio.Button>
                            <Radio.Button value="FEMALE">
                              <WomanOutlined /> {t("female")}
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="col col-12 col-md-6">
                        <Form.Item
                          label={t("companyName")}
                          name="companyName"
                          rules={ruleStringRequired}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="col col-12 col-md-6">
                        <Form.Item label={t("phone")} name="phone">
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="col col-12 col-md-6">
                        <Form.Item label={t("fax")} name="fax">
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col col-12 col-lg-12">
                    <p className="sub-l">{t("additionalInformation")}</p>
                    <div className="row">
                      <div className="col col-12 col-md-6">
                        <Form.Item
                          label={t("vatNumber")}
                          name="vatId"
                          rules={[{ required: true }]}
                        >
                          <Input maxLength={30} />
                        </Form.Item>
                      </div>
                      <div className="col col-12 col-md-6">
                        <Form.Item
                          label={t("currency")}
                          name="currencyExchangeId"
                          rules={[{ required: true }]}
                          >
                          <Select
                            showSearch
                            placeholder=""
                            disabled={disabledCurrencySelect}
                            loading={disabledCurrencySelect}
                          >
                            {currencies.map((currency: any) => (
                              <Option key={currency.id} value={currency.id}>
                                {currency.isoCode} - {currency.symbol}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="col col-12 col-lg-6 mt-3">
                    <AddressForm
                      form={form}
                      data={profile?.billingAddress}
                      title={t("billingAddress")}
                      name="billingAddress"
                    />
                    <Checkbox
                      onChange={onIsSameShippingAddress}
                      checked={!!isSameShippingAddress}
                    >
                      {t("address.same.note")}
                    </Checkbox>
                  </div>
                  <div className="col col-12 col-lg-6 mt-3">
                    <AddressForm
                      form={form}
                      data={profile?.shippingAddress}
                      title={t("shippingAddress")}
                      name="shippingAddress"
                      required={false}
                      disabled={isSameShippingAddress}
                    />
                  </div>
                </div>
                <hr className="mt-3" />
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t("saveChanges")}
                  </Button>
                </Form.Item>
              </Form>
            )}
            {keySelected === "bulk-pricing" && (
              <FadeIn>
                <FormBulkPricingSetting
                  data={profile}
                  form={form}
                  onFinish={onFinish}
                />
              </FadeIn>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={t("changePassword")}
        open={isModalOpen}
        onOk={formPassword.submit}
        onCancel={handleCancel}
      >
        <Form
          form={formPassword}
          layout="vertical"
          name="basic"
          onFinish={onChangePasswordFinish}
          autoComplete="off"
        >
          <Form.Item
            label={t("oldPassword")}
            name="password"
            rules={rulePassword}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("newPassword")}
            name="newPassword"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={t("confirmPassword")}
            name="confirmNewPassword"
            rules={[
              { required: true },
              {
                validator: async (_, confirmPassword) => {
                  if (
                    formPassword.getFieldValue("newPassword") != confirmPassword
                  ) {
                    return Promise.reject(
                      new Error(t("validate.confirmPassword") || "")
                    );
                  }
                  return true;
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
