import {
  CheckOutlined,
  EditOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  UndoOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Popover,
  Space,
  notification,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ListParts from "../../components/Part/ListParts";
import CountUnreadProjects from "../../components/Project/CountUnreadProjects";
import ProjectActions from "../../components/Project/ProjectActions";
import ProjectPrices from "../../components/Project/ProjectPrice";
import ProjectStatusTag from "../../components/Project/ProjectStatusTag";
import { Order } from "../../models/Order";
import { Part } from "../../models/Part";
import { ListViewMode, Project, ProjectStatus } from "../../models/Project";
import { ShippingOption } from "../../models/ShippingOption";
import { User } from "../../models/User";
import { UserRole } from "../../models/UserRole";
import { authSelector } from "../../redux/slides/auth.slide";
import { loadingActions } from "../../redux/slides/loading.slide";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import { unreadActions } from "../../redux/slides/unread.slide";
import { setAuthorization } from "../../services";
import projectServices from "../../services/project.service";
import "./projectDetail.module.scss";
import VATNoIcon from "app/components/SVGs/VATNoIcon";

export function ProjectDetailPage() {
  const { id, accessCode } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const { data } = useSelector(projectSelector);
  const [project, setProject] = useState<Project>();
  const [order, setOrder] = useState<Order>();
  const [customer, setCustomer] = useState<User>();
  const [dataAuto, setDataAuto] = useState<Part[]>();
  const [dataManual, setDataManual] = useState<Part[]>();
  const [partsPrice, setPartsPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [adaptForm] = Form.useForm();
  const [loadingAdaptDeliveryDate, setLoadingAdaptDeliveryDate] =
    useState(false);
  const [isOpenOriginalDeliveryDate, setIsOpenOriginalDeliveryDate] =
    useState(false);
  const [isAdaptDeliveryDate, setIsAdaptDeliveryDate] = useState(false);
  const [adaptDeliveryDate, setAdaptDeliveryDate] = useState(
    project?.order.adaptedDeliveryDate || project?.order.deliveryDate
  );

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < moment().add(14, "days").endOf("day");
  };

  const getCountUnread = async (statuses: ProjectStatus[], key: any) => {
    try {
      const rs = await projectServices.countUnread({ statuses });
      dispatch(unreadActions.setData({ key, data: rs }));
      return rs;
    } catch (error) {}
    return 0;
  };
  const loadCountUnread = async () => {
    if (!!project && auth.user?.role === UserRole.BUYER) {
      switch (project.status) {
        case ProjectStatus.CHECKOUTED:
        case ProjectStatus.REQUESTED_OFFER:
          await getCountUnread(
            CountUnreadProjects.STATUS_KEYS.BUYER_GROUP2_SUB1,
            "BUYER_GROUP2_SUB1"
          );
          break;
        case ProjectStatus.PRICE_ADAPTED:
          await getCountUnread(
            CountUnreadProjects.STATUS_KEYS.BUYER_GROUP2_SUB2,
            "BUYER_GROUP2_SUB2"
          );
          break;
        default:
          break;
      }
    }
    if (!!project && auth.user?.role === UserRole.SELLER) {
      switch (project.status) {
        case ProjectStatus.CHECKOUTED:
        case ProjectStatus.REQUESTED_OFFER:
          console.log("getCountUnread", ProjectStatus.REQUESTED_OFFER);

          await getCountUnread(
            CountUnreadProjects.STATUS_KEYS.SELLER_GROUP1,
            "GROUP1"
          );
          break;
        case ProjectStatus.PRICE_ADAPTED:
          await getCountUnread(
            CountUnreadProjects.STATUS_KEYS.SELLER_GROUP2,
            "GROUP2"
          );
          break;
        default:
          break;
      }
    }
    if (!!project) {
      switch (project.status) {
        case ProjectStatus.SELLER_APPROVED:
        case ProjectStatus.BUYER_APPROVED:
          await getCountUnread(
            CountUnreadProjects.STATUS_KEYS.GROUP3,
            "GROUP3"
          );
          break;
        case ProjectStatus.SELLER_REJECTED:
        case ProjectStatus.BUYER_REJECTED:
          await getCountUnread(
            CountUnreadProjects.STATUS_KEYS.GROUP4,
            "GROUP4"
          );
          break;
        default:
          break;
      }
    }
  };

  const getProject = async () => {
    dispatch(loadingActions.show(true));
    try {
      let rs: any;
      if (id) {
        rs = await projectServices.detail(id);
      } else if (accessCode) {
        setAuthorization(undefined);
        rs = await projectServices.viewByCode(accessCode);
      }
      if (rs) {
        if (
          (auth.user?.role === UserRole.BUYER && !rs.readByBuyer) ||
          (auth.user?.role === UserRole.SELLER && !rs.readBySeller)
        ) {
          await projectServices.markAsRead(id);
        }
        dispatch(projectActions.setProject(rs));
        if (
          rs.status === ProjectStatus.IN_PROGRESS &&
          auth.user?.role === UserRole.BUYER
        ) {
          navigate(`/${auth.user?.role}/projects/${id}/calculations`);
          return;
        }

        setDataAuto(rs.parts?.filter((p: any) => p.auto));
        setDataManual(rs.parts?.filter((p: any) => !p.auto));
        setOrder(rs.order);
        setCustomer(rs.selfCalculation ? rs.offerUser : rs.createdBy);
      }
    } catch (error) {
      navigate(`/${auth.user?.role || ""}`);
    }
    setLoading(false);
    dispatch(loadingActions.show(false));
  };

  const onCompletePayment = () => {
    getProject();
  };

  useEffect(() => {
    getProject();
    // return () => {
    //   dispatch(projectActions.setProject(null));
    //   dispatch(projectActions.setDeliveryCost(null));
    //   dispatch(projectActions.setDeliveryOption(null));
    // };
  }, [id]);
  useEffect(() => {
    if (
      project &&
      ((auth.user?.role === UserRole.BUYER && !project.readByBuyer) ||
        (auth.user?.role === UserRole.SELLER && !project.readBySeller))
    ) {
      loadCountUnread();
    }
  }, [project]);
  useEffect(() => {
    if (data) {
      setProject(data);
    }
  }, [data]);
  useEffect(() => {
    if (dataAuto) {
      const total = dataAuto.reduce((accumulator, object) => {
        return accumulator + object.totalPrice;
      }, 0);
      setPartsPrice(total);
    }
  }, [dataAuto]);

  const onAdaptDeliveryDate = async (values: any) => {
    setLoadingAdaptDeliveryDate(true);
    try {
      const rs = await projectServices.adaptDeliveryDate({
        projectId: id,
        deliveryDate: values.deliveryDate.format("YYYY-MM-DD"),
      });
      setProject(rs);
      dispatch(projectActions.setProject(rs));
      api.info({
        message: `Adapt delivery date`,
        description: (
          <>
            Change delivery date to{" "}
            {moment(values.deliveryDate.format("YYYY-MM-DD")).format("L")}!
          </>
        ),
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: `Adapt delivery date`,
        description: <>Failed!</>,
        placement: "topRight",
      });
    }
    setLoadingAdaptDeliveryDate(false);
    setIsAdaptDeliveryDate(false);
  };
  const onAdaptDeliveryDateChange = (e: any) => {
    setAdaptDeliveryDate(e.format("YYYY-MM-DD"));
  };
  const onAdaptReset = () => {
    adaptForm.setFieldValue("deliveryDate", dayjs(project?.order.deliveryDate));
    setAdaptDeliveryDate(project?.order.deliveryDate);
  };

  if (loading || !project) return <></>;
  return (
    <div>
      {contextHolder}
      <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3">
        <h4 className="me-3 mb-0">{project?.name}</h4>
        <ProjectStatusTag status={project.status} className="fs-6 p-2" />
        <ProjectActions
          className="ms-md-auto"
          project={project}
          accessCode={accessCode}
        />
      </div>
      <hr />
      <div className="row">
        <div className="col col-12 col-md-9">
          <div className="row">
            <div className="col col-6 col-md-3">
              <p className="text-label mb-2">
                {t("project.table.orderNumber")}
              </p>
              <label className="fw-bold">{project.order.orderNumber}</label>
            </div>
            <div className="col col-6 col-md-3">
              <p className="text-label mb-2">{t("project.noOfPart")}</p>
              <label className="fw-bold">{project.parts?.length}</label>
            </div>
            <div className="col col-6 col-md-3">
              <p className="text-label mb-2">{t("deliveryOption")}</p>
              <label className="fw-bold">
                {t(`deliveryOption.${project.deliveryOption}`)}
              </label>
            </div>
            <div className="col col-6 col-md-3">
              <p className="text-label mb-2">{t("project.deliveryDate")}</p>
              {project.order.adaptedDeliveryDate && !isAdaptDeliveryDate && (
                <Popover
                  overlayStyle={{
                    maxWidth: "20vw",
                  }}
                  placement="right"
                  content={
                    <>
                      {t("project.adapted.deliveryDate", {
                        deliveryDate: moment(project.order.deliveryDate).format(
                          "LL"
                        ),
                        adaptedDeliveryDate: moment(
                          project.order.adaptedDeliveryDate
                        ).format("LL"),
                      })}
                    </>
                  }
                >
                  <span className="fw-bold">
                    {moment(project.order.adaptedDeliveryDate).format("LL")}{" "}
                    {/* {project.order.adaptedDeliveryDate && <><WarningOutlined className="text-danger" />{" "}</>} */}
                    {auth.user?.role === UserRole.SELLER &&
                      (project.status === ProjectStatus.CHECKOUTED ||
                        project.status === ProjectStatus.REQUESTED_OFFER) && (
                        <a
                          className="text-primary ms-2"
                          onClick={setIsAdaptDeliveryDate.bind(null, true)}
                        >
                          <EditOutlined />
                        </a>
                      )}
                  </span>
                </Popover>
              )}
              {!project.order.adaptedDeliveryDate && (
                <>
                  {!isAdaptDeliveryDate && (
                    <label className="fw-bold">
                      {moment(project.order.deliveryDate).format("LL")}{" "}
                      {/* {<WarningOutlined />} */}
                      {auth.user?.role === UserRole.SELLER &&
                        (project.status === ProjectStatus.CHECKOUTED ||
                          project.status === ProjectStatus.REQUESTED_OFFER) && (
                          <a
                            className="text-primary ms-2"
                            onClick={setIsAdaptDeliveryDate.bind(null, true)}
                          >
                            <EditOutlined />
                          </a>
                        )}
                    </label>
                  )}
                </>
              )}
              {isAdaptDeliveryDate && (
                <Form
                  form={adaptForm}
                  className="app-form"
                  initialValues={{
                    projectId: id,
                    deliveryDate: dayjs(
                      project.order?.adaptedDeliveryDate ||
                        project.order?.deliveryDate
                    ),
                  }}
                  onFinish={onAdaptDeliveryDate}
                  disabled={loadingAdaptDeliveryDate}
                >
                  <Form.Item name="projectId" hidden>
                    <Input />
                  </Form.Item>
                  <Space.Compact>
                    <Form.Item className="mb-0">
                      <Button
                        type="default"
                        icon={<UndoOutlined />}
                        disabled={
                          project.order.deliveryDate === adaptDeliveryDate
                        }
                        onClick={onAdaptReset}
                      />
                    </Form.Item>
                    <Popover
                      placement="left"
                      content={
                        <>
                          <p className="mb-2 fw-bold">
                            {t("originalDeliveryDate")}
                          </p>
                          {moment(project.order.deliveryDate).format("L")}
                        </>
                      }
                      trigger="hover"
                      open={isOpenOriginalDeliveryDate}
                    >
                      <Form.Item name="deliveryDate" className="mb-0">
                        <DatePicker
                          allowClear={false}
                          onFocus={setIsOpenOriginalDeliveryDate.bind(
                            null,
                            true
                          )}
                          onBlur={setIsOpenOriginalDeliveryDate.bind(
                            null,
                            false
                          )}
                          onChange={onAdaptDeliveryDateChange}
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Popover>
                    <Form.Item className="mb-0">
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loadingAdaptDeliveryDate}
                        icon={<CheckOutlined />}
                        disabled={
                          (project.order.adaptedDeliveryDate ||
                            project.order.deliveryDate) === adaptDeliveryDate
                        }
                      />
                    </Form.Item>
                  </Space.Compact>
                </Form>
              )}
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              <p className="text-label mb-2">
                {t("project.label.shippingOption")}
              </p>
              <label>
                {project.order.shippingComment || <i>{t("empty")}</i>}
              </label>
            </div>
            {project.order.shippingOption != ShippingOption.STANDARD && (
              <div className="col col-4">
                <p className="text-label mb-2">
                  {t("project.shippingOptions")}
                </p>
                <label>
                  {t(`shippingOption.${project.order.shippingOption}.msg`)}
                </label>
              </div>
            )}
          </div>
          <hr />
          <div className="row mt-3">
            <div className="col col-md-6">
              <div className="fw-bold">{t("billingAddress")}</div>
              <div className="d-flex flex-column gap-1 mt-2">
                <div>
                  <EnvironmentOutlined className="me-2" />{order?.billingAddress?.streetName}{" "}
                  {order?.billingAddress?.houseNumber},{" "}
                  {order?.billingAddress?.city},{" "}
                  {order?.billingAddress?.postCode}{" "}
                  {order?.billingAddress?.state},{" "}
                  {order?.billingAddress?.country}
                </div>
                {order?.billingAddress?.email && (
                  <div>
                    <MailOutlined className="me-2" />{order?.billingAddress?.email}
                  </div>
                )}
                {order?.billingAddress?.phone && (
                  <div>
                    <PhoneOutlined className="me-2" /> {order?.billingAddress?.phone}
                  </div>
                )}
              </div>
            </div>
            <div className="col col-md-6">
              <div className="fw-bold">{t("shippingAddress")}</div>
              <div className="d-flex flex-column gap-1 mt-2">
                <div>
                  <EnvironmentOutlined className="me-2"/>{order?.shippingAddress?.streetName}{" "}
                  {order?.shippingAddress?.houseNumber},{" "}
                  {order?.shippingAddress?.city},{" "}
                  {order?.shippingAddress?.postCode}{" "}
                  {order?.shippingAddress?.state},{" "}
                  {order?.shippingAddress?.country}
                </div>
                {order?.shippingAddress?.email && (
                  <div>
                    <MailOutlined className="me-2" />{order?.shippingAddress?.email}
                  </div>
                )}
                {order?.shippingAddress?.phone && (
                  <div>
                    <PhoneOutlined className="me-2" />{order?.shippingAddress?.phone}
                  </div>
                )}
              </div>
            </div>
          </div>
          {auth?.user?.role == UserRole.SELLER && (
            <div className="row mt-3">
              <div className="col col-md-12">
                <Descriptions
                  title={t("payment")}
                  size={"small"}
                  layout="vertical"
                >
                  <Descriptions.Item label={t("payment.note")}>
                    {order?.paymentComment}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          )}
          {!project.manual &&
            !project.selfCalculation &&
            project.adapted &&
            project.status !== ProjectStatus.CHECKOUTED && (
              <Alert
                className="mt-2"
                type="warning"
                showIcon
                icon={<WarningOutlined />}
                message={
                  auth.user?.role == UserRole.BUYER
                    ? t("project.adapted")
                    : t("project.adapted.seller")
                }
                description={
                  <>
                    {project.order.adaptedDeliveryDate && (
                      <p className="mb-0">
                        {t("project.adapted.deliveryDate", {
                          deliveryDate: moment(
                            project.order.deliveryDate
                          ).format("LL"),
                          adaptedDeliveryDate: moment(
                            project.order.adaptedDeliveryDate
                          ).format("LL"),
                        })}
                      </p>
                    )}
                    {project.parts?.some((p) => p.adaptedUnitPrice) && (
                      <p>{t("project.adapted.price")}</p>
                    )}
                  </>
                }
              />
            )}
          <ListParts
            project={project}
            mode={
              project.status === ProjectStatus.CHECKOUTED ||
              project.status === ProjectStatus.REQUESTED_OFFER
                ? ListViewMode.PRICE_ADAPT
                : ListViewMode.READ_ONLY
            }
          />
        </div>
        <div className="col col-12 col-md-3">
          {project.status !== ProjectStatus.IN_PROGRESS && (
            <>
              {(auth.user?.role === UserRole.SELLER ||
                project.status !== ProjectStatus.REQUESTED_OFFER) && (
                <Card
                  className="project-summary mb-4 b-shadow"
                  title={t("project.orderSummary")}
                >
                  <ProjectPrices
                    project={project}
                    onCompletePayment={onCompletePayment}
                    accessCode={accessCode}
                  />
                </Card>
              )}
            </>
          )}
          <Card
            className="customer-info b-shadow"
            title={t("project.customerInfo")}
          >
            <Space direction="vertical" size={12} className="w-100">
              {(
                (customer?.firstName || "") + (customer?.lastName || "")
              ).trim() && (
                <div className="d-flex align-items-center">
                  <UserOutlined />{" "}
                  <label className="ms-3">{`${customer?.firstName || ""} ${
                    customer?.lastName || ""
                  }`}</label>
                </div>
              )}
              <a href={`mailto:${customer?.email}`}>
                <div className="d-flex align-items-center">
                  <MailOutlined />{" "}
                  <label className="ms-3">{customer?.email || "--"}</label>
                </div>
              </a>

              {customer?.phone && (
                <a href={`tel:${customer.phone}`}>
                  <div className="d-flex align-items-center">
                    <PhoneOutlined />{" "}
                    <label className="ms-3">{customer.phone || "--"}</label>
                  </div>
                </a>
              )}
              {customer?.companyName && (
                <div className="d-flex align-items-center">
                  <HomeOutlined />{" "}
                  <label className="ms-3">
                    {customer?.companyName || "--"}
                  </label>
                </div>
              )}
              {customer?.vatId && (
                <div className="d-flex align-items-center">
                  <VATNoIcon width={"1rem"} height={"1rem"} />{" "}
                  <label className="ms-3">{customer?.vatId || "--"}</label>
                </div>
              )}
            </Space>
          </Card>
        </div>
      </div>
    </div>
  );
}
