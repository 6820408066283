import { createSlice } from "@reduxjs/toolkit";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    captchaEnabled: false,
    keySiteCaptcha: "",
    shopType: "",
    gaTrackingCode: "",
    gtmTrackingCode: "",
    sellerMail: "",
    supportMail: "",
    locales: [],
    paymentEnabled: false,
    technicalDrawingRequired: false,
    subfolderFrontendUrl: "",
    shopCountryCode: "",
    shopCountryVatRate: "",
    shopMaterial: ""
  },
  reducers: {
    setData: (state, action) => {
      state.shopType = process.env.REACT_APP_SHOPTYPE ? process.env.REACT_APP_SHOPTYPE : action.payload.shopType;
      state.captchaEnabled = action.payload.captchaEnabled;
      state.keySiteCaptcha = action.payload.keySiteCaptcha;
      state.gaTrackingCode = action.payload.gaTrackingCode;
      state.gtmTrackingCode = action.payload.gtmTrackingCode;
      state.sellerMail = action.payload.sellerMail;
      state.supportMail = action.payload.supportMail || "support@gocad.de";
      state.locales = action.payload.locales;
      state.paymentEnabled = action.payload.paymentEnabled;
      state.technicalDrawingRequired = action.payload.technicalDrawingRequired;
      state.subfolderFrontendUrl = action.payload.subfolderFrontendUrl;
      state.shopCountryCode = action.payload.shopCountryCode;
      state.shopCountryVatRate = ((action.payload.shopCountryVatRate ?? 0 )* 100).toString();
      state.shopMaterial = action.payload.shopMaterial
    },
  },
});

export const configActions = configSlice.actions;
export const configSelector = (state: any) => state.config;
export default configSlice.reducer;
