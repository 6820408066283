import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { CurrencyExchange } from "app/models/User";
import { UserRole } from "app/models/UserRole";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import { projectActions } from "app/redux/slides/project.slide";
import Utils from "app/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Project, ProjectStatus } from "../../models/Project";
import projectServices from "../../services/project.service";
import InputCurrencyInline from "../Form/InputCurrencyInline";
import CurrencyFormat from "../Format/CurrencyFormat";
import PaymentForm from "../Payment/PaymentForm";
import PaymentFormModal from "../Payment/PaymentFormModal";
import ProjectListManualPaymentFormModal from "../Payment/ProjectListManualPaymentFormModal";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import SurfaceTreatmentSurcharge from "./SurfaceTreatmentSurcharge";
import TotalPartPrice from "./TotalPartPrice";
import "./projectPrice.module.scss";

const projectAdaptShippingStatuses = [
  ProjectStatus.CHECKOUTED,
  ProjectStatus.REQUESTED_OFFER,
  ProjectStatus.PRICE_ADAPTED,
  ProjectStatus.SELLER_APPROVED
]
interface DefaultProps {
  project: Project;
  onCompletePayment?: any;
  accessCode?: string;
}
function ProjectPrices(props: DefaultProps) {
  const { project, onCompletePayment, accessCode } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [deliveryCost, setDeliveryCost] = useState(project.order?.price);
  const [downloadingPreviewOrder, setDownloadingPreviewOrder] = useState(false);
  const [triggerShowModal, setTriggerShowModal] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaid, setShowPaid] = useState(false);
  const [showVat, setShowVat] = useState(false);
  const [showLoadingPaid, setLoadingPaid] = useState(false);
  const config = useSelector(configSelector);
  const auth = useSelector(authSelector);
  const [projectCurrency, setProjectCurrency] = useState<any>(null);

  useEffect(() => {
    setDeliveryCost(project.order?.price);
    checkPaymentStatus();
    if (project?.order?.shippingAddress?.countryCode == config.shopCountryCode) {
      setShowVat(true);
    } else {
      setShowVat(false);
    }
    if (project) {
      const currencyInfo: CurrencyExchange = {
        exchangeRate: project?.order?.price?.currencyExchangeRate,
        symbol: project?.order?.price?.currencySymbol,
        isoCode: project?.order?.price?.currencyIsoCode,
      };

      setProjectCurrency(currencyInfo);
    }
  }, [project]);

  const onDownloadPreviewOffer = async () => {
    try {
      setDownloadingPreviewOrder(true);
      await projectServices.downloadPreviewOrder({
        id: project.id,
        name: project.name || "GOCAD",
      });
    } catch (error) {}
    setDownloadingPreviewOrder(false);
  };

  const handleOnCompletePayment = () => {
    setTriggerShowModal(false);
    setShowPayment(false);
  };

  const checkPaymentStatus = async () => {
    if (!config?.paymentEnabled || !project?.id) return;

    try {
      setLoadingPaid(true);
      await Utils.waitForSeconds(3);
      let rs: any;

      if (accessCode) {
        rs = await projectServices.viewByCode(accessCode);
      } else if (project?.id) {
        rs = await projectServices.detail(project?.id);
      }

      setLoadingPaid(false);
      if (!rs?.order?.paid) {
        setShowPayment(true);
        setShowPaid(false);
      } else {
        setShowPaid(true);
        setShowPayment(false);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const onShippingCostChange = async (value: any) => {
    if (project.id) {
      try {
        const rs = await projectServices.adaptShippingCost({
          projectId: project.id,
          shippingCost: value / projectCurrency.exchangeRate,
        });
        dispatch(projectActions.setProject(rs));
      } catch (error) {}
    }
  };

  if (!deliveryCost) return <></>;
  return (
    <div className="w-100 summary-price">
      <TotalPartPrice price={deliveryCost} currencyExchange={projectCurrency} />
      <SurfaceTreatmentSurcharge
        price={deliveryCost}
        currencyExchange={projectCurrency}
      />
      <div className="d-flex">
        <label>
          {t("price.deliveryOptionPrice")} (
          {t(`deliveryOption.${project.deliveryOption}`)})
        </label>
        <label className="ms-auto">
          <CurrencyFormat
            value={deliveryCost.deliveryOptionPrice}
            currencyExchange={projectCurrency}
          />
        </label>
      </div>
      <div className="d-flex">
        <label>{t("price.packagingPrice")}</label>
        <label className="ms-auto">
          <CurrencyFormat
            value={deliveryCost.packagingPrice}
            currencyExchange={projectCurrency}
          />
        </label>
      </div>
      <div className="d-flex shipping-cost">
        <label>{t("price.shipping")}</label>
        <label className="ms-auto">
          {projectAdaptShippingStatuses.includes(project.status) && auth.user?.role !== UserRole.BUYER && (
            <>
              <InputCurrencyInline
                name="shipping"
                value={deliveryCost.adaptedShipping ?? deliveryCost.shipping}
                callBack={onShippingCostChange}
                adapted={deliveryCost.shippingAdapted}
                currencyInfo={{
                  exchangeRate: deliveryCost?.currencyExchangeRate,
                  symbol: deliveryCost?.currencySymbol,
                  isoCode: deliveryCost?.currencyIsoCode,
                }}
                inputProps={{
                  min: 0
                }}
              />
            </>
          )}
          {(auth.user?.role === UserRole.BUYER || !projectAdaptShippingStatuses.includes(project.status)) && (
            <>
              <CurrencyFormat
                value={deliveryCost.shipping}
                currencyExchange={projectCurrency}
                adaptPrice={deliveryCost.adaptedShipping}
              />
            </>
          )}
        </label>
      </div>
      <div className="text-muted">
        {(project.order.price?.shipping ?? project.order.price?.adaptedShipping) === null && <Alert className="mb-2" type="warning" icon={<WarningOutlined/>} showIcon message={t("validate.shippingCost")} />}
        <i>{t(`shippingOption.${project?.order?.shippingOption}`)}</i>
      </div>
      <hr />
      <div className="d-flex fw-bold">
        <label>{t("price.netTotal")}</label>
        <label className="ms-auto">
          <CurrencyFormat
            value={deliveryCost.netTotal}
            currencyExchange={projectCurrency}
          />
        </label>
      </div>
      {showVat && (
        <div className="d-flex">
          <label>{t("price.vat", { vat: config.shopCountryVatRate })}</label>
          <label className="ms-auto">
            <CurrencyFormat
              value={deliveryCost.vat}
              currencyExchange={projectCurrency}
            />
          </label>
        </div>
      )}
      <hr />
      <div className="d-flex fw-bold">
        <label>{t("price.total")}</label>
        <label className="ms-auto total-price">
          <CurrencyFormat
            value={deliveryCost.total}
            currencyExchange={projectCurrency}
          />
        </label>
      </div>
      <hr />
      <div className="row">
        <div className="col col-md-7 col-xs-12">
          <a
            onClick={onDownloadPreviewOffer}
            className={downloadingPreviewOrder ? "isDisabled " : ""}
            style={{ textDecoration: "none" }}
          >
            <PdfFileIcon /> {t("project.checkout.previewOffer")}
            {downloadingPreviewOrder && <LoadingOutlined spin />}
          </a>
        </div>
      </div>
      {showLoadingPaid && (
        <div className="row mt-2">
          <div className="col col-md-12 col-xs-12 btn-pay-full-width text-center">
            <LoadingOutlined />
          </div>
        </div>
      )}
      {showPayment && auth?.user?.role == UserRole.BUYER && (
        <div className="row mt-2">
          <div className="col col-md-12 col-xs-12 btn-pay-full-width">
            <PaymentFormModal
              triggerShowModal={triggerShowModal}
              project={project}
            >
              <PaymentForm
                onCompletePayment={handleOnCompletePayment}
                project={project}
              ></PaymentForm>
            </PaymentFormModal>
          </div>
        </div>
      )}
      {showPayment && auth?.user?.role == UserRole.SELLER && (
        <div className="row mt-2">
          <div className="col col-md-12 col-xs-12 btn-pay-full-width">
            <ProjectListManualPaymentFormModal
              project={project}
              onComplete={handleOnCompletePayment}
            />
          </div>
        </div>
      )}
      {showPaid && <div className="paid-stamp"></div>}
    </div>
  );
}
export default ProjectPrices;
